<template>
  <Modal class="default polices p-blur" ref="modal" :settings="{ opened: true }" @close="onClose" @open="onOpen">
    <div class="polices-title" slot="header">
      <p class="sec-font">My Space 1A</p>
    </div>
    <div class="polices-content">
      <div class="polices-info">
        <Avatar class="polices-info-avatar" :user="user" />
        <div class="polices-text">
          <p class="polices-info-title">Políticas del usuario</p>
          <p class="polices-info-name">{{ user.artisticName || user.name }}</p>
        </div>
      </div>
      <div class="polices-list">
        <UserPolicy v-for="(policy, index) in polices" :key="index" :policy="policy" @change="onChange" :user="user" />
      </div>
      <div class="polices-options">
        <div :class="`polices-options-message ${isOk}`" v-if="!saving">
          <span>{{ message }}</span>
        </div>
        <div class="polices-options-content" v-if="hasChanges">
          <a class="polices-options-saving" v-if="saving">
            <iconic name="gspinner" />
            <span>Guardando...</span>
          </a>
          <a class="primary-button button" @click="onSave" v-else>
            <span>Guardar</span>
          </a>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import UserPolices from "./UserPolices.js";
export default {
  components: {
    UserPolicy: () => import("./UserPolicy.vue"),
  },
  data: function() {
    return {
      hasChanges: false,
      newPolices: {},
      saving: false,
      message: "",
      isOk: "_start",
    };
  },
  methods: {
    onClose: function() {
      this.$store.state.user.adminPermissions = null;
    },
    onOpen: function() {},
    onChange: function({ policy }) {
      this.hasChanges = true;
      this.newPolices[policy.key] = policy;
      this.message = "No se han guardado los cambios";
      this.isOk = "_pending";
    },
    onSave: async function() {
      this.saving = true;
      const dataReq = { user: this.user, polices: this.newPolices };
      const { data } = await this.$api.post(`polices`, dataReq);
      this.saving = false;

      if (!data.success) {
        this.message = data.message;
        this.isOk = "_error";
        return;
      }

      if (data.user) {
        this.$store.state.user.adminPermissions = data.user;
        this.message = data.message;
        this.hasChanges = false;
        this.isOk = "_success";
      }
    },
  },
  computed: {
    user: function() {
      return this.$store.state.user.adminPermissions;
    },
    polices: function() {
      return Object.keys(UserPolices).map((key) => {
        return UserPolices[key];
      });
    },
  },
};
</script>

<style lang="scss">
.polices {
  @include Backdrop(5px, rgba(0, 0, 0, 0.5));
  &-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  &-title {
    font-size: 120%;
    padding: 0 0 0 $mpadding;
  }
  &-info {
    padding: $mpadding;
    display: flex;
    gap: $mpadding;
  }
  &-info-name {
    font-size: 120%;
    font-weight: bold;
  }
  &-info-avatar {
    width: 50px;
    height: 50px;
  }
  &-options {
    margin: auto 0 0 0;
    position: sticky;
    bottom: 0;
  }
  &-options-content {
    padding: $mpadding;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-top: solid 1px $alto;
    .button {
      padding: $mpadding/2 $mpadding;
    }
  }
  &-options-saving {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $mpadding/2;
    pointer-events: none;
  }
  &-options-message {
    padding: $mpadding/2 $mpadding;
    background-color: #fff;
    &._error {
      color: red;
    }
    &._success {
      color: green;
    }
  }
}
</style>
