export default {
  "can-edit-profile": {
    label: "Puede editar su perfil",
    key: "can-edit-profile",
  },
  "models-stars-limit": {
    label: "Tiene acceso a modelos con un rango de estrellas inferior o igual a:",
    key: "models-stars-limit",
    roles: ["monitor"],
    hasValue: true,
    valueType: "number",
  },
  "acccess-to-novelties": {
    label: "El monitor Tiene Acceso a crear novedades",
    key: "acccess-to-novelties",
    roles: ["monitor"],
    hasValue: false,
    valueType: "number",
  },
  "see-from": {
    label: "Este usuario podra ver registros desde la fecha: ",
    key: "see-from",
    roles: ["monitor"],
    hasValue: true,
    valueType: "Date",
  },
};
